module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-web-font-loader@1.0.4/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Inter"]}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-gtag@5.13.0_gatsby@5.13.1_@types+webpack@4.41.38_babel-eslint@10.1.0_esl_ckd5v3y47qazvewjypb4xrnmdi/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-7V0XH93DBV"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-styled-components@6.13.0_babel-plugin-styled-components@2.1.4_@babel+core@7.24._ycjylkt3z72hys7tupbyycwjam/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":false,"minify":false,"transpileTemplateLiterals":false,"pure":true,"fileName":true,"namespace":"","topLevelImportPaths":[],"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.1_@types+webpack@4.41.38_babel-eslint@10.1.0_eslint@8.23.0__react-dom@18.2.0_reac_fshopkfenlsl5sx26audcrscbi/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
